import NativeAudioControler from '@/baseEngine/nativeAudioControler'
import { AudioPlayer } from '@xiaoyezi/musicscore-engine'
import BtnLock from '@/baseEngine/btnLock'

export default {
  data: () => ({
    speedCn: 1,
    audioPlayer: null,
    playSeekTime: 0,
    audioControler: null,
    progress: null,
    canplay: false, // 首次等待客户端加载音频
    ready: false, // 首次等musicScore待客户端加载音频
    firstLoadMidi: false,
    loadSuccess: false
  }),
  methods: {
    async initAudioPlay () {
      this.audioPlayer = new AudioPlayer({ useWeb: true })

      this.audioPlayer.off('loadSuccess')
      this.audioPlayer.on('loadSuccess', () => {
        setTimeout(() => {
          this.loadSuccess = true
        }, 500)
        console.log('initAudioPlay->loadSuccess')
      })

      await this.audioPlayer.load({
        bgm: this.noteResultsData.audio_url
      })

      this.audioPlayer.off('progress')
      this.audioPlayer.on('progress', (progress) => { // 音频播放进度，毫秒
        this.audioControler.currentTime = progress
        if (!this.ready) {
          this.progress = progress
          this.ready = true
          return
        }
        if (!this.canplay && this.progress !== progress) {
          this.canplay = true
          this.audioControler.play()
        }
      })

      this.audioPlayer.off('end')
      this.audioPlayer.on('end', () => {
        console.log('listen->end')
        this.stopAudition()
      })

      this.audioControler = new NativeAudioControler(this.noteResultsData)

      this.audioControler.off('scoreNoteId')
      this.audioControler.on('scoreNoteId', scoreNoteId => {
        if (scoreNoteId || Number(scoreNoteId) === 0) {
          const note = this.musicScore.getNoteById(scoreNoteId)
          if (note) this.cursor.update(note)
        }
      })

      this.audioControler.off('end')
      this.audioControler.on('end', () => {
        console.log('audioControler->end')
        this.stopAudition()
      })
    },

    // 获取试听乐句起始结束noteId
    loadAuditionMidi () {
      this.firstLoadMidi = true
      // 提升endtick 需要修正tick，减去时值
      this.audioControler.load()

      this.playSeekTime = this.audioControler.playerCurrentTime
    },

    // 试听开始播放
    startAudition () {
      if (!this.noteResultsData.audio_url) {
        console.error('没有音频文件')
        return
      }

      if (this.listenPlaying || !this.loadFinish || !this.loadSuccess) {
        console.error('试听音频准备中，请稍候重试')
        return
      }

      if (BtnLock.lock) {
        console.log('开始试听->操作过于频繁')
        return
      }
      BtnLock.initLock(500)

      if (!this.firstLoadMidi) this.loadAuditionMidi()

      // 更新各种状态
      this.listenPlaying = true
      this.cursor._followScore = true
      this.cursor.visible = true
      this.cursor.forceUpdate()

      this.audioPlayer.rate = this.speedCn
      this.audioPlayer.seek(0)

      // 播放器开始播放
      setTimeout(() => {
        this.audioPlayer.play()
      }, 200)
    },

    // 关闭试听
    stopAudition () {
      if (!this.listenPlaying) return

      if (BtnLock.lock) {
        console.log('停止试听->操作过于频繁')
        return
      }
      BtnLock.initLock(500)

      this.ready = false
      this.canplay = false
      this.listenPlaying = false
      this.audioPlayer.stop()
      this.audioPlayer.reset()
      this.audioControler.pause()
    }
  }
}
