import { AiMidiLoader } from '@xiaoyezi/musicscore-engine'
export default {
  data: () => ({
    aiMidiLoader: null,
    reportSigner: null
  }),
  methods: {
    // one文件高亮分手分段,获取起始note
    initRect () {
      this.aiMidiLoader = new AiMidiLoader()
      this.aiMidiLoader.load(this.oneData, this.scoreData.pages)
      const events = this.aiMidiLoader.rawMidi._events.filter(v => v.on === 1)
      const a = this.noteResultsData.A || 0
      const b = this.noteResultsData.B - 1
      let endNote = null
      if (events[a]) this.reportStartNote = this.musicScore.getNoteById(events[a].score.noteId) || null
      if (events[b]) endNote = this.musicScore.getNoteById(events[b].score.noteId) || null
      if (this.reportStartNote) {
        this.cursor.update(this.reportStartNote)
        this.cursor.forceUpdate()
      }

      const notesCount = this.aiMidiLoader.rawMidi._events.length / 2
      this.isQuanQu = !this.noteResultsData.A && (this.noteResultsData.B === notesCount || !this.noteResultsData.B) && !this.noteResultsData.hand
      // 如果非全曲（分手分段）需要显示背景色
      if (!this.isQuanQu && this.noteResultsData.version === 0) {
        this.createMusicScoreAB(this.reportStartNote, endNote, this.noteResultsData.hand.toString(), false)
      }
    },
    // 5.0高亮分手分段,获取起始note
    initNewRect () {
      this.aiMidiLoader = new AiMidiLoader()
      let noteIdStart = 0
      let noteIdEnd = 0
      const events = this.playDataLoader.rawMidi._events
      if (this.noteResultsData.A_tick !== null && this.noteResultsData.B_tick !== null) {
        this.noteResultsData.B_tick -= 2
        noteIdStart = this.playDataLoader.tick2Events(this.noteResultsData.A_tick)[0].score.noteId
        noteIdEnd = this.playDataLoader.tick2Events(this.noteResultsData.B_tick)[0].score.noteId
      } else {
        for (let i = 0; i < events.length; i++) {
          if (events[i].type !== 'rest') {
            noteIdStart = events[i].score.noteId
            break
          }
        }
      }
      this.reportStartNote = this.musicScore.getNoteById(noteIdStart) || null
      let endNote = null
      if (noteIdEnd) {
        endNote = this.musicScore.getNoteById(noteIdEnd) || null
      }
      if (this.reportStartNote) {
        this.cursor.update(this.reportStartNote)
        this.cursor.forceUpdate()
      }

      this.isQuanQu = !this.noteResultsData.A_tick && !this.noteResultsData.B_tick && !this.noteResultsData.hand

      // 如果非全曲（分手分段）需要显示背景色
      if (this.pageType === 'report' && !this.isQuanQu) {
        if (noteIdStart < noteIdEnd || noteIdEnd === 0) {
          this.createMusicScoreAB(this.reportStartNote, endNote, this.noteResultsData.hand.toString(), false)
        } else {
          this.reportSigner._getNewSystemIds({
            startScoreTick: this.noteResultsData.A_tick,
            endScoreTick: this.noteResultsData.B_tick
          })
          this.reportSigner.createFluencys(this.reportStartNote, endNote, -1, 'rgba(255, 228, 92, .25)')
        }
      }
    }
  }
}
