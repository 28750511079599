import EventEmitter from 'event-emitter-es6'

export default class NativeAudioControler extends EventEmitter {
  constructor (syncData) {
    super()
    this.currentPhraseIndex = 0 // 当前乐句第一个note的index
    this.playerIndex = 0 // 当前乐句第一个note，自增
    this.playerEnd = 0 // 当前乐句最后一个note
    this.playerCurrentTime = 0 // 当前乐句第一个note对应的时间
    this._syncData = syncData // 评测数据
    this._currentTime = 0 // 当前音频时间

    this._noteDict = {} // 点击音符数据
    this._playVideoStatus = false // 播放状态
    this._playNotes = [] // 双手播放队列
    this._speed = 1 // 音频播放速度
    this._phraseName = '' // 乐句名称
    this._playerTime = null // 定时器
    this._endTimer = null // 结束

    this._makePlayNote()
  }

  getNoteDict (id) {
    this.playerIndex = this._noteDict[id]
    return this.playerIndex
  }

  getCurrentNote (id) {
    return this._playNotes[id]
  }

  load (startTick = 0, endTick = 0, phraseName = '', diff = 1) { // diff,分乐句+2，测评+1;
    console.log('load->tick', startTick, endTick)
    const playStartNote = this._findPlayNote(this._playNotes, startTick)
    const playEndNote = endTick ? this._findPlayNote(this._playNotes, endTick) : this._playNotes[this._playNotes.length - 1]
    this.currentPhraseIndex = playStartNote.index
    this.playerIndex = playStartNote.index
    this.playerEnd = playEndNote.index + diff
    this.playerCurrentTime = playStartNote.userTime

    this.emit('scoreNoteId', playStartNote.scoreNoteId)

    this._phraseName = phraseName
    if (phraseName.includes('全曲') || phraseName === 'all') {
      this.playerEnd = Math.max(this._playNotes.length, this.playerEnd)
    }
  }

  play () {
    const currentNote = this._playNotes[this.playerIndex]
    this.emit('scoreNoteId', currentNote.scoreNoteId)

    this._playVideoStatus = true
    this._auditionIng()
  }

  pause () {
    this._playVideoStatus = false
    clearTimeout(this._playerTime)
    this.playerIndex = this.currentPhraseIndex
    this._currentTime = this.playerCurrentTime

    // 播放结束之后定位到第一个音符上
    const currentNote = this._playNotes[this.currentPhraseIndex]
    this.emit('scoreNoteId', currentNote.scoreNoteId)
  }

  playerCurrentTime () {
    return this.playerCurrentTime
  }

  set speed (s) {
    this._speed = s
  }

  set currentTime (t) {
    this._currentTime = t
  }

  // 播放进行中, 递归进行
  _auditionIng () {
    if (this._playVideoStatus) {
      if (this.playerIndex < this.playerEnd && this._playNotes[this.playerIndex + 1]) {
        const currentNote = this._playNotes[this.playerIndex]
        const nextNote = this._playNotes[this.playerIndex + 1]
        const currentTime = this._currentTime

        // 判断音源播放时间和音符位置时间是否匹配
        if (currentTime < nextNote.userTime) {
          const duration = (nextNote.userTime - currentTime) / this._speed
          this.emit('scoreNoteId', currentNote.scoreNoteId)
          clearTimeout(this._playerTime)
          this._playerTime = setTimeout(() => {
            this.playerIndex += 1
            this._auditionIng()
          }, duration)
        } else {
          // 如果音源播放时间没有到当前音符，则等待
          this.playerIndex += 1
          this.emit('scoreNoteId', nextNote.scoreNoteId)
          this._auditionIng()
        }
      } else {
        clearTimeout(this._playerTime)
        this.playerIndex = this.currentPhraseIndex

        // 播放全曲结束之后定位到最后一个音符上
        const currentNote = this._playNotes[this._playNotes.length - 1]
        this.emit('scoreNoteId', currentNote.scoreNoteId)

        clearTimeout(this._endTimer)
        this._endTimer = setTimeout(() => { // 2秒之内没收到结束消息，主动触发结束逻辑（兼容安卓）
          if (this._playVideoStatus) {
            this.emit('end')
          }
        }, 2000)
      }
    }
  }

  // 根据notations的数据生成异常notes信息
  _makeAbnormalNotes (notations) {
    const notes = {}
    notations.forEach(item => {
      if ('scoreNoteId' in item) {
        const scoreNoteId = String(item.scoreNoteId)
        const key = String(item.userTime)
        if ('userTime' in item) {
          if (key in notes) {
            notes[key][scoreNoteId] = item.type
          } else {
            const obj = {}
            obj[scoreNoteId] = item.type
            notes[key] = obj
          }
        }
      }
    })
    return notes
  }

  // 生成回放音频的播放数据
  _makePlayNote () {
    const { results, notations } = this._syncData
    const abNotes = this._makeAbnormalNotes(notations)
    let start = -1
    let index = 0
    for (let i = 0; i < results.length; i++) {
      const item = results[i]
      if ('scoreNoteId' in item) {
        const scoreNoteId = item.scoreNoteId
        const key = String(item.userTime)
        let status = item.pitch
        if (key in abNotes) {
          if (String(scoreNoteId) in abNotes[key]) {
            status = abNotes[key][String(scoreNoteId)]
          }
        }
        if (item.userTime > start) {
          this._playNotes.push({
            notes: [{ scoreNoteId: scoreNoteId, status: status }],
            scoreNoteId: scoreNoteId,
            userTime: item.userTime,
            index: index,
            tick: item.scoreTick
          })
          this._noteDict[String(scoreNoteId)] = this._noteDict[String(scoreNoteId)] ? this._noteDict[String(scoreNoteId)] : index
          start = item.userTime
          index += 1
        } else if (this._playNotes[index - 1]) {
          const obj = { scoreNoteId: scoreNoteId, status: status }
          this._playNotes[index - 1].notes.push(obj)
          this._noteDict[String(scoreNoteId)] = this._noteDict[String(scoreNoteId)] ? this._noteDict[String(scoreNoteId)] : index - 1
        }
      }
    }
    this.playerEnd = this._playNotes.length
  }

  // 二分法查找数据
  _findPlayNote (notes, time) {
    if (notes.length === 1) {
      return notes[0]
    } else if (notes.length === 2) {
      const midValue = (notes[0].tick + notes[1].tick) / 2
      if (time <= midValue) {
        return notes[0]
      } else {
        return notes[1]
      }
    } else {
      const midd = parseInt(notes.length / 2)
      const leftMidValue = (notes[midd].tick + notes[midd - 1].tick) / 2
      const rightMidValue = (notes[midd].tick + notes[midd + 1].tick) / 2
      if (time <= leftMidValue) {
        return this._findPlayNote(notes.slice(0, midd), time)
      } else if (time > rightMidValue) {
        return this._findPlayNote(notes.slice(midd + 1), time)
      } else {
        return notes[midd]
      }
    }
  }
}
